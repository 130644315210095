
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";

/**import style */
import "../assets/css/custom.css"

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

function ServiceList(userDetails) {

  const routeName = userDetails.routes
  const featureName = userDetails.userDetails.featureNameList
  let result = featureName.includes(routeName);
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusUpdate, setStatusUpdate] = useState();

  /**coulumn fields */
  const columns = [
    {
      dataField: 'name',
      text: 'Service Name',
      align: 'center'
    }, {
      dataField: 'image',
      text: 'Image',
      formatter: imageFormatter,
      align: 'center'

    },
    {
      dataField: 'url',
      text: 'Url',
      align: 'center'
    }, {
      dataField: '',
      text: '',
      formatter: editPermission,
      align: 'center'

    },
    {
      dataField: 'status',
      text: '',
      align: 'center',
      formatter: buttonStatus,
    }

  ];


  /**image formatting for display */
  function imageFormatter(cell, row) {
    return (<img src={cell} style={{ width: 50, height: 48 }} alt="img" />)
  }

  /**for status button */
  function buttonStatus(cell, row) {
    if (cell === 1) {
      return (<Button className="btn-fill" color="info" type="button" onClick={() => statusChange(row)}>Enabled</Button>)
    }
    else {
      return (<Button className="btn-fill" color="danger" type="button" onClick={() => statusChange(row)}>Disabled</Button>)

    }

  }

  /**status enable/disable button function*/
  const statusChange = async (row) => {

    let id = row.id;

    let status = row.status;

    let changeStatus = status ? 0 : 1;

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');
    let config = {
      method: 'put',
      url: `${apiUrl}services/${id}`,
      headers: { 'Authorization': token },
      data: {
        service: {
          status: changeStatus
        }
      },
    };


    const responseStatus = await axios(config)


    if (responseStatus.status === 200) {
      toast.success("Status updated successfully");
      setStatusUpdate(changeStatus);

    }
  }

  /**edit permission */
  function editPermission(cell, row) {
    return (<a className="edit-permission-btn" onClick={() => editServicePage(row)}><i className="fa fa-edit edit-icon"></i></a>)

  }

  const editServicePage = async (row) => {
    let id = row.id;
    history.push({ pathname: '/admin/editservice', state: { detail: id } });

  }

  // use effect
  useEffect(() => {

    async function fetchData() {

      const apiUrl = process.env.REACT_APP_API_URL;
      const token = sessionStorage.getItem('currentToken');

      let config = {
        method: 'get',
        url: `${apiUrl}services`,
        headers: { 'Authorization': token },
      };

      const getValue = await axios(config)

      setServices(getValue.data.services);

      console.log(getValue.data.services)
      if (getValue.data) {
        setLoading(false)
      }
      else {
        setLoading(true)
      }
    }
    fetchData()
  }, [statusUpdate]);




  const addService = () => {
    history.push("/admin/addservice");
  }

  return (
    <>
      {result ?
        <div className="content">

          <Row>
            <Col md="12">
              <Card>

                <CardHeader>
                  <Row>

                    <Col md="10">
                      <CardTitle tag="h4">Services</CardTitle>
                    </Col>
                    <Col md="2" className="add-user-btn">
                      <Button className="btn-fill" color="info" type="button" onClick={addService}>
                        Add New
                      </Button>
                    </Col>

                  </Row>
                </CardHeader>

                <CardBody>
                  {loading ? <Loader type="ThreeDots" color="#3358f4" height={30} width={30} /> :

                    <BootstrapTable bootstrap4 keyField='id' className="bootstrap-table"
                      striped hover condensed data={services}
                      pagination={paginationFactory()} columns={columns} />
                  }

                </CardBody>
              </Card>
            </Col>

          </Row>

        </div> :
        <div className="content">
          <Row className="unauthorized-msg-block">

            <CardBody className="unauthorized-msg-body">
              <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
            </CardBody>

          </Row>


        </div>
      }


    </>
  );
}

export default ServiceList;
