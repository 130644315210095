/**dependencies */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, FormGroup, CardTitle, Form, Input, Row, Col, } from "reactstrap";

import InputPermissionFeatures from 'components/InputPermissionFeatures/InputPermissionFeatures';

/**import style */
import "../assets/css/custom.css";

toast.configure()

function EditPermissionView(props) {
  const routeName = props.routes
  const featureName = props.userDetails.featureNameList
  let result = featureName.includes(routeName);
  const history = useHistory();
  const [permissionName, setPermissionName] = useState();

  const location = useLocation();
  const [permissionId, setPermissionId] = useState(location.state.permission_id);

  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const [isLoading, setIsLoading] = useState(false)

  const validations = {
    permissionName: /^(?:[a-zA-Z]+ ?)+$/,
  }

  useEffect(() => {
    const permissionId = location.state.permission_id

    setPermissionId(permissionId);
    setPermissionName(location.state.permisssion_name);

    async function loadData() {
      setIsLoading(true)

      await getFeatures();
      await getPermissionData(permissionId);

      setIsLoading(false)
    }

    loadData();
  }, [location]);

  /**get permission details for edit */
  async function getPermissionData(permissionId) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');

    let config = {
      method: 'get',
      url: `${apiUrl}permissions/${permissionId}`,
      headers: { 'Authorization': token },
    };

    const response = await axios(config)
    const services = response.data.permission.services

    const f = services.reduce((features, service) => ([
      ...features,
      ...service.features.map(feature => JSON.stringify({ id: feature.id, readonly: feature.readonly })),
    ]), [])

    setSelectedFeatures(f)

  }

  /**get featrue options */
  async function getFeatures() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');
    let config = {
      method: 'get',
      url: `${apiUrl}features`,
      headers: { 'Authorization': token },
    };

    const response = await axios(config)
    setFeatures(response.data.features)
  }

  const isFormValid = () => {
    return permissionName.match(validations.permissionName)
      && selectedFeatures.length > 0
  }

  const onSubmit = async () => {
    if (isFormValid()) {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = sessionStorage.getItem('currentToken');
      let config = {
        method: 'put',
        url: `${apiUrl}permissions/${permissionId}`,
        headers: { 'Authorization': token },
        data: {
          permission: {
            name: permissionName,
            features: selectedFeatures.map(feature => JSON.parse(feature))
          }
        }
      };

      /**Sweet alert */

      Swal.fire({
        title: 'Are you sure you want to update permission details?',
        showCancelButton: true,
        confirmButtonText: 'Ok',

      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const submitAddPermissions = await axios(config)

            toast.success(submitAddPermissions.data.message);
            if (submitAddPermissions.status === 200) {
              history.push({ pathname: '/admin/permissions' });

            }

          } catch (error) {
            if (error.response) {
              toast.error(error.response.data.message)
            }
            else {
              toast.error("Oops...Something went wrong...")
            }
          }
        }
        else {
          Swal.fire('Changes are not saved', '', 'info')

        }
      })

    } else {
      toast.warning("Please fill valid inputs!");
    }

  }

  const onCancel = async () => {

    history.push({ pathname: '/admin/permissions' });

  }

  return (
    <>
      {result ?
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Edit Permission</CardTitle>
                </CardHeader>
                {
                  isLoading
                    ? <Loader type="ThreeDots" color="#3358f4" className="m-3" height={30} width={30} />
                    : <>
                      <CardBody>
                        <Form>
                          <Row>
                            <Col className="pr-md-1" md="3">
                              <FormGroup>
                                <label>Permission Name</label>
                                <Input
                                  type="text"
                                  value={permissionName}
                                  onChange={event => setPermissionName(event.target.value)}
                                  valid={permissionName?.match(validations.permissionName)}
                                  invalid={!permissionName?.match(validations.permissionName)}
                                />

                              </FormGroup>
                            </Col>
                          </Row>

                          <InputPermissionFeatures
                            options={features}
                            values={selectedFeatures}
                            onChange={values => setSelectedFeatures(values)}
                          />

                        </Form>
                      </CardBody>
                      <CardFooter>

                        <Button className="btn-fill" color="info" type="submit" onClick={onSubmit}>
                          Save Changes
                        </Button>

                        <Button className="btn-fill" color="info" type="submit" onClick={onCancel}>
                          Cancel
                        </Button>
                      </CardFooter>
                    </>
                }
              </Card>
            </Col>
          </Row>
        </div> :
        <div className="content">
          <Row className="unauthorized-msg-block">

            <CardBody className="unauthorized-msg-body">
              <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
            </CardBody>

          </Row>


        </div>
      }

    </>
  );
}

export default EditPermissionView;