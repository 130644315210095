/**import dependencies */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

import { isDataUrl } from 'libs/validators';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Form,
  Row,
  Col,
  Label,
} from "reactstrap";

import Select from "react-select"

import { useHistory } from "react-router-dom";

import InputUserPermissions from 'components/InputUserPermissions/InputUserPermissions';

import "../assets/css/custom.css"

function AddUserView(userDetails) {
  const routeName = userDetails.routes
  const featureName = userDetails.userDetails.featureNameList
  let result = featureName.includes(routeName);
  const history = useHistory();
  const [email, setEmail] = useState();
  const [designation, setDesignation] = useState()
  const [departmentId, setDepartmentId] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userPermissionOptions, setUserPermissionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [uploadFile, setUploadFile] = useState();
  const [permissions, setPermissions] = useState([]);
  const [fullName, setFullName] = useState();

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');

    axios({
      method: 'get',
      url: `${apiUrl}departments`,
      headers: { 'Authorization': token },
    }).then(res => {
      const { departments } = res.data

      setDepartmentOptions(departments)
    })

  }, [])

  // validations for inputs
  const validations = {
    phoneNumber: /^(?:(0)|\+?(\d{1,4}))?[-. ]?(\d{3})[-. ]?(\d{3})[-. ]?(\d{4})$/,
    fullName: /^(?:[a-zA-Z]+ ?)+$/,
    designation: /^(?:[a-zA-Z]+ ?)+$/,
  }

  useEffect(() => {
    getPermissions();
  }, []);

  /**get permission list in dropdown */
  async function getPermissions() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');
    let config = {
      method: 'get',
      url: `${apiUrl}permissions`,
      headers: { 'Authorization': token },
    };

    const response = await axios(config)
    let departmentsConfig = {
      method: 'get',
      url: `${apiUrl}departments`,
      headers: { 'Authorization': token },
    };

    const responseDepartments = await axios(departmentsConfig)

    const permissions = response.data.permissions.map (permission => responseDepartments.data.departments.map(d => ({
      ...permission, 
      department : d,
  }
  ))).flat();



    setUserPermissionOptions(permissions)
  }

  const isFormValid = () => {
    return phoneNumber?.match(validations.phoneNumber)
      && fullName?.match(validations.fullName)
      && designation?.match(validations.designation)
      && validator.isEmail(email)
      && isDataUrl("image")(uploadFile)
  }

  /**get profile image */
  const profileImgChange = (e) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => { setUploadFile(reader.result) }
    if (file) reader.readAsDataURL(file)

  }

  /**submit the form */
  const onSubmit = async () => {

    if (isFormValid()) {

      const apiUrl = process.env.REACT_APP_API_URL;
      const token = sessionStorage.getItem('currentToken');
      let config = {
        method: 'post',
        url: `${apiUrl}users`,
        headers: { 'Authorization': token },
        data: {
          user: {
            "email": email,
            "profileImage": uploadFile,
            "phone": phoneNumber,
            "designation": designation,
            "departmentId": departmentId,
            "permissions": permissions.map(permission => { 
              let newPermission = JSON.parse(permission)
              return ({  id : newPermission.id, departmentId : newPermission.departmentId , })}),
            "fullName": fullName
          }

        }
      };
      try {
        const submitValues = await axios(config);

        toast.success(submitValues.data.message);
        if (submitValues.status === 200) {
          history.push({ pathname: '/admin/users' });
        }

      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message)
        }
        else {
          toast.error("Oops...Something went wrong...")
        }
      }

    }

    else {
      toast.warning("Please fill valid inputs!");
    }


  }

  const onCancel = async () => {
    history.push({ pathname: '/admin/users' });

  }


  return (
    <>
      {result ?
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Add User</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="3">
                        <FormGroup>
                          <label>Full Name</label>
                          <Input
                            placeholder="Full Name"
                            type="text"
                            required="required"
                            value={fullName}
                            onChange={event => setFullName(event.target.value)}
                            valid={fullName?.match(validations.fullName)}
                            invalid={!fullName?.match(validations.fullName)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="3">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Email
                          </label>
                          <Input
                            placeholder="email"
                            type="email"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            valid={validator.isEmail(email)}
                            invalid={!validator.isEmail(email)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="3">
                        <FormGroup>
                          <label>Designation</label>
                          <Input
                            placeholder="Designation"
                            type="text"
                            value={designation}
                            onChange={event => setDesignation(event.target.value)}
                            valid={designation?.match(validations.designation)}
                            invalid={!designation?.match(validations.designation)}

                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="3">
                        <FormGroup>
                          <Label>Department</Label>
                          <Select
                            name="department"
                            options={departmentOptions.map(department => (
                              { label: department.name, value: department.id }
                            ))}
                            onChange={d => setDepartmentId(d.value)}
                            value={(() => {
                              const department = departmentOptions.find(department => department.id === departmentId)
                              return department
                                ? { label: department.name, value: department.id }
                                : null
                            })()}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <label>Phone Number</label>
                          <Input
                            value={phoneNumber}
                            placeholder="Phone Number"
                            type="text"
                            onChange={event => setPhoneNumber(event.target.value)}
                            valid={phoneNumber?.match(validations.phoneNumber)}
                            invalid={!phoneNumber?.match(validations.phoneNumber)}
                          />

                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label for="exampleInputFile">Profile Picture</label>
                          <Input
                            id="profile-upload"
                            name="file"
                            type="file"
                            onChange={profileImgChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <img src={uploadFile} alt="img" />
                        </FormGroup>
                      </Col>

                    </Row>

                    <InputUserPermissions
                      options={userPermissionOptions}
                      values={permissions}
                      onChange={values => setPermissions(values)}
                    />

                  </Form>
                </CardBody>
                <CardFooter>
                  <Button className="btn-fill" color="info" type="submit" onClick={onSubmit}>
                    Submit
                  </Button>
                  <Button className="btn-fill" color="info" type="submit" onClick={onCancel}>
                    Cancel
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
        :
        <div className="content">
          <Row className="unauthorized-msg-block">

            <CardBody className="unauthorized-msg-body">
              <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
            </CardBody>

          </Row>


        </div>
      }
    </>
  );
}

export default AddUserView;
