import React, { useEffect} from 'react';
import queryString from "query-string"


import Authenticate from "./Authenticate";
import {Link } from "react-router-dom";



function GetAuthToken () { 

   let parsedToken = queryString.parse(window.location.hash)
     
    useEffect(() => {
       if( parsedToken.id_token ) {

         sessionStorage.setItem('currentToken', parsedToken.id_token);
         

       } else {

         parsedToken = sessionStorage.getItem('currentToken');
      

       }
    }, []);
 
    return (
        <div data-testid="main-header">
     
           { parsedToken ? <Authenticate {...parsedToken} /> : <Link to={process.env.REACT_APP_LOGIN_URL} /> }
  
        </div>
     )
  }
 

export default GetAuthToken