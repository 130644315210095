
const isDataUrl = (type = "") => (value) => {
    // if value is empty, pass validation; not required field
    if (!value || value.length === 0) return true

    const dataUrlSchema = /^data:(?<type>.+);base64,(?<base64>.+)$/
    // fail validation if not data url
    const groups = dataUrlSchema.exec(value)?.groups
    if (!groups) return false

    // fail validation if type doesn't match the required type
    if (!groups.type?.includes(type)) return false

    // fail validation if base64 not present
    if (!groups.base64) return false
    
    return true
}

export {
    isDataUrl,
}