/**import dependencies */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/**import style */
import "../assets/css/custom.css"


// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";


function PermissionList(userDetails) {
  const routeName = userDetails.routes
  const featureName = userDetails.userDetails.featureNameList
  let result = featureName.includes(routeName);
  const history = useHistory();
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [featureListing, setFeatureListing] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState();
  const [loadFeatures, setLoadFeatures] = useState(true)


  /** use effect */
  useEffect(() => {

    async function fetchData() {

      const apiUrl = process.env.REACT_APP_API_URL;
      const token = sessionStorage.getItem('currentToken');


      let config = {
        method: 'get',
        url: `${apiUrl}permissions`,
        headers: { 'Authorization': token },
      };

      const getValue = await axios(config)

      setPermissions(getValue.data.permissions)
      if (getValue.data) {
        setLoading(false);
      }
      else {
        setLoading(true)
      }
    }
    fetchData()
  }, [statusUpdate]);

  const columns = [

    {
      dataField: 'name',
      text: 'Permission Name',
    },
    {
      dataField: '',
      text: '',
      formatter: editPermission,
      align: 'center'

    },
    {
      dataField: 'status',
      text: '',
      formatter: buttonStatus,
      align: 'center'

    },
  ];

  /**for status button */
  function buttonStatus(cell, row) {
    if (cell === 1) {
      return (<Button className="btn-fill" color="info" type="button" onClick={() => statusChange(row)}>Enabled</Button>)
    }
    else {
      return (<Button className="btn-fill" color="danger" type="button" onClick={() => statusChange(row)}>Disabled</Button>)

    }

  }

  /**status enable/disable button function*/
  const statusChange = async (row) => {
    let id = row.id;
    let status = row.status;
    let changeStatus = status ? 0 : 1;

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');
    let config = {
      method: 'put',
      url: `${apiUrl}permissions/${id}`,
      headers: { 'Authorization': token },
      data: {
        permission: {
          status: changeStatus,
        }
      },
    };

    const responseStatus = await axios(config)

    toast.success(responseStatus.data.message);

    if (responseStatus.status === 200) {

      setStatusUpdate(changeStatus);

    }
  }

  /**edit permission */
  function editPermission(cell, row) {

    return (<a className="edit-permission-btn" onClick={() => editPermissionPage(row)}><i className="fa fa-edit edit-icon"></i></a>)

  }

  const editPermissionPage = (row) => {

    let id = row.id;
    let name = row.name

    history.push({ pathname: '/admin/editpermission', state: { permission_id: id, permisssion_name: name } });

  }

  /**expand row */
  const expandRow = {
    renderer: row => (
      <div>
        <div className="row">
          <div className="col-md-12 service-feature-listing">
            <div class="col-md-4">
              <h4>Service Name</h4>
            </div>
            <div className="col-md-8">
              <h4>Features</h4>
            </div>
          </div>
        </div>


        {loadFeatures ? <Loader type="ThreeDots" color="#3358f4" height={30} width={30} /> :
          <div>
            {featureListing.map(function (service, i) {
              return (
                <div className="row" key={i}>
                  <div className="col-md-12 service-feature-listing">
                    <div class="col-md-4" >
                      <p>{service.name}</p>
                    </div>
                    <div className="col-md-8 ">
                      {service.features.map(function (feature, i) {
                        return (
                          <span key={i} >
                            <span className="tag ">{
                              `${feature.name}${feature.readonly
                                ? ".readonly"
                                : ""
                              }`
                            }</span>
                          </span>

                        )
                      })
                      }
                    </div>

                  </div>
                </div>

              )
            })
            }
          </div>

        }

      </div>

    ),

    onlyOneExpanding: true, //for expanding only row at a time
    expandByColumnOnly: true,
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandColumnAlign: 'center',
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <b></b>;
      }
      return <b></b>;
    },
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      const onclick = async (event) => {
        let id = rowKey
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = sessionStorage.getItem('currentToken');
        let config = {
          method: 'get',
          url: `${apiUrl}permissions/${id}`,
          headers: { 'Authorization': token },
        };

        const getFeatures = await axios(config)

        if (getFeatures.data.permission.services) {
          setLoadFeatures(false);
        }
        else {
          setLoadFeatures(true)
        }
        setFeatureListing(getFeatures.data.permission.services)

      }

      if (!expandable) {
        return;
      }
      return (
        <Button type="button" onClick={onclick} className="btn-fill" color="info">View Features</Button>
      );
    }

  };




  const addPermission = () => {
    history.push("/admin/addpermission");
  }


  return (
    <>

      {result ?
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="8">
                      <CardTitle tag="h4">Permissions</CardTitle>
                    </Col>
                    <Col md="4" className="add-user-btn">
                      <Button className="btn-fill" color="info" type="button" onClick={addPermission}>
                        Add New
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>

                  {loading ? <Loader type="ThreeDots" color="#3358f4" height={30} width={30} /> :
                    <BootstrapTable bootstrap4 keyField='id' className="bootstrap-table"
                      striped hover condensed data={permissions}
                      pagination={paginationFactory()} columns={columns} expandRow={expandRow} >
                    </BootstrapTable>
                  }
                </CardBody>
              </Card>
            </Col>

          </Row>

        </div> :
        <div className="content">
          <Row className="unauthorized-msg-block">

            <CardBody className="unauthorized-msg-body">
              <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
            </CardBody>

          </Row>


        </div>
      }


    </>
  );
}





export default PermissionList;
