
import Dashboard from "./views/Dashboard";
import PermissonList from "./views/PermissonList";
import ServiceList from "./views/ServiceList";
import UserList from "./views/UserList";
import AddServiceView from "./views/AddServiceView";
import AddPermissionView from "./views/AddPermissionView";
import AddUserView from "./views/AddUserView";
import FeatureList from "./views/FeatureList";
import EditPermissionView from "./views/EditPermissionView";
import EditServicesView from "./views/EditServicesView";
import EditUserView from "./views/EditUserView";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "nav-icon fas fa-tachometer-alt",
    component: Dashboard,
    layout: "/admin",
    feature:"dashboard"
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "خرائط",
    icon: "fas fa-users",
    component: UserList,
    layout: "/admin",
    feature:"administration.users.entries.readonly"
  },
  {
    path: "/permissions",
    name: "Permissions",
    rtlName: "الرموز",
    icon: "fa fa-user-tag",
    component: PermissonList,
    layout: "/admin",
    feature:"administration.permissions.readonly"
  },
  {
    path: "/services",
    name: "Services",
    rtlName: "إخطارات",
    icon: "fa fa-hands-helping",
    component: ServiceList,
    layout: "/admin",
    feature:"administration.services.readonly"
  },
  {
    path: "/features",
    name: "Features",
    rtlName: "إخطارات",
    icon: "fa fa-tasks",
    component: FeatureList,
    layout: "/admin",
    feature:"administration.services.features.readonly"
  },
  {
    path: "/addservice",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "",
    component: AddServiceView,
    layout: "/admin",
    feature:"administration.services"
  },
  {
    path: "/addpermission",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "",
    component: AddPermissionView,
    layout: "/admin",
    feature:"administration.permissions"
  },
  {
    path: "/adduser",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "",
    component: AddUserView,
    layout: "/admin",
    feature:"administration.users.entries"
  },
  {
    path: "/editpermission",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "",
    component: EditPermissionView,
    layout: "/admin",
    feature:"administration.permissions"
  },
  {
    path: "/editservice",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "",
    component: EditServicesView,
    layout: "/admin",
    feature:"administration.services"
  },
  {
    path: "/edituser",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "",
    component: EditUserView,
    layout: "/admin",
    feature:"administration.users.entries"
  },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-align-center",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "ار تي ال",
  //   icon: "tim-icons icon-world",
  //   component: Rtl,
  //   layout: "/rtl",
  // },
];
export default routes;
