import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from "react-select";
import { Row, Col, FormGroup, Label, CardHeader, CardTitle, Input, Button } from "reactstrap";

function InputPermissionFeatures(props) {
    const { options, values, onChange } = props

    const [services, setServices] = useState({});
    const [features, setFeatures] = useState([]);

    const [selectedServices, setSelectedServices] = useState(new Set())
    const [selectedFeatures, setSelectedFeatures] = useState([]);

    const selectServiceOptions = useMemo(() => (
        Object.entries(services).filter(([serviceId, _service]) => (
            !selectedServices.has(parseInt(serviceId))
        )).map(([serviceId, service]) => ({
            value: parseInt(serviceId),
            label: service,
        }))
    ), [selectedServices, services])

    const selectFeatureOptions = useCallback((serviceId) => (
        features.filter(feature => (
            feature.serviceId === serviceId
        )).map(feature => ([
            { label: feature.name, value: JSON.stringify({ id: feature.id, readonly: 0 }) },
            { label: feature.name + ".readonly", value: JSON.stringify({ id: feature.id, readonly: 1 }) },
        ])).flat()
    ), [features])

    useEffect(() => {
        setFeatures(options)

        const newServices = {};
        options.forEach(feature => (
            newServices[feature.serviceId] = feature.serviceName
        ))
        setServices(newServices)
    }, [options])

    useEffect(() => {
        if (!values || !features) return;

        setSelectedServices(s => (new Set([
            ...s,
            ...new Set(features.filter(feature => (
                values.some(value => JSON.parse(value).id === feature.id)
            )).map(feature => feature.serviceId)),
        ])))

        setSelectedFeatures(values.filter(value => (
            !!features.find(feature => feature.id === JSON.parse(value).id)
        )))
    }, [features, values])

    const parentOnChange = (newFeatures) => {
        onChange && onChange([...newFeatures])
    }

    const selectService = async (selected, _meta) => {
        if (!selected) return
        const selectedService = parseInt(selected.value)
        setSelectedServices(s => new Set(s).add(selectedService))
    }

    const removeService = serviceId => {
        setSelectedServices(s => {
            const newSet = new Set(s)
            newSet.delete(serviceId)
            return newSet
        })

        setSelectedFeatures(s => {
            const newSet = new Set([...s].filter(featureId => (
                features.find(feature => feature.id === featureId)?.serviceId !== serviceId
            )))

            parentOnChange(newSet)

            return newSet
        })
    }

    const selectFeature = (values, meta) => {

        setSelectedFeatures(s => {

            const newSet = new Set(s)

            switch (meta.action) {
                case "select-option":
                    let feature = meta.option.value
                    newSet.add(feature)
                    break
                case "clear":
                    meta.removedValues.forEach(value => (
                        newSet.delete(value)
                    ))
                    break
                case "remove-value":
                    let removedValue = meta.removedValue.value
                    newSet.delete(removedValue)
                    break
                default: break
            }
            parentOnChange(newSet)

            return newSet
        })
    }

    return <div>
        <CardHeader>
            <CardTitle tag="h4">Permission Features</CardTitle>
        </CardHeader>
        {
            [...selectedServices].map(serviceId => (
                <Row key={serviceId}>
                    <Col className="pr-md-1" md="4">
                        <FormGroup>
                            <Label>Service</Label>
                            <Input value={services[serviceId]} readOnly />
                        </FormGroup>
                    </Col>

                    <Col className="pr-md-1" md="4">
                        <FormGroup>
                            <Label>Select Features</Label>
                            <Select
                                isMulti
                                name="features"
                                options={selectFeatureOptions(serviceId)} // Options to display in the dropdown
                                onChange={selectFeature}
                                value={[...selectedFeatures].filter((feature) => {
                                    const { id } = JSON.parse(feature)
                                    return features.find(feature => feature.id === id)?.serviceId === serviceId
                                }).map(feature => {
                                    const { id, readonly } = JSON.parse(feature)
                                    return {
                                        label: features.find(feature => feature.id === id).name + (
                                            readonly ? ".readonly" : ""
                                        ),
                                        value: feature,
                                    }
                                })}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pr-md-1 pt-3" md="1">
                        <Button color="danger" size='sm' className='mt-3' onClick={() => removeService(serviceId)} >-</Button>
                    </Col>
                </Row>
            ))
        }
        {
            selectServiceOptions?.length > 0
            && <Row>

                <Col className="pr-md-1" md="4">
                    <FormGroup>
                        <label for="serviceSelect">Select Service</label>
                        <Select name="services" options={selectServiceOptions} onChange={selectService} value={null} id="serviceSelect" />
                    </FormGroup>
                </Col>

            </Row>
        }
    </div>
}

export default InputPermissionFeatures