import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { Button, FormGroup, Input, Row, Col, Label, CardTitle, CardHeader } from "reactstrap";
import axios from 'axios';


function InputUserPermissions(props) {

    const [permissions, setPermissions] = useState({});
    const [selectedPermissions, setSelectedPermissions] = useState(new Set());
    const [services, setServices] = useState({})
    const [selectedServices, setSelectedServices] = useState(new Set())
    const [selectServiceOptions, setSelectServiceOptions] = useState([])

    useEffect(() => {
        const permissions = props.options
        setPermissions(permissions)
        const services = {}
        Object.values(permissions).forEach(permission => {
            Object.entries(permission.services).forEach(([key, serviceName]) => {
                let serviceId = Number(key)
                let service = services[serviceId]
                if (!service) service = {name: serviceName, permissions: []}
                services[serviceId] = {
                    ...service,
                    permissions: [
                        ...service.permissions,
                        permission
                    ]
                }
            })
            })
        setServices(services)
        props.values && setSelectedPermissions(new Set(props.values))
    }, [props.options, props.values])

    useEffect(() => {
        const newServices = new Set(selectedServices)
        props.values?.forEach(permissionIds => {
            const {id, departmentId} = JSON.parse(permissionIds)
            if (! (permissions[id])) return
            Object.keys((permissions.find(ele => id === ele.id && departmentId === ele.department.id).services)).forEach(serviceId => (
                newServices.add(Number(serviceId))
            ))
        })
        updateSelectedServices(newServices)
    }, [permissions, props.values])

    const parentOnChange = (newPermissions) => {
        props.onChange && props.onChange([...newPermissions])
    }

    const selectPermission = (values, meta) => {
        const newSet = new Set(selectedPermissions)
        switch (meta.action) {
          case "select-option": 
            let permissionId = meta.option.value
            newSet.add(permissionId)    
            const newServices = new Set(selectedServices)
              Object.keys((permissions.find(ele => {
              let newPermissionId = JSON.parse(permissionId)
              return  newPermissionId.id === ele.id &&
              newPermissionId.departmentId === ele.department.id}).services)).forEach(serviceId => (
              newServices.add(Number(serviceId))
            ))
            updateSelectedServices(newServices)
            break
          case "clear": 
            meta.removedValues.forEach(value => (
              newSet.forEach(item => { 
                let newItem = JSON.parse(item)
                let newValue = JSON.parse(value.value)
                if (newItem.departmentId === newValue.departmentId && 
                    newItem.id === newValue.id) newSet.delete(item); })
            ))
            break
          case "remove-value":
            let removedValue = meta.removedValue.value
            newSet.forEach(item => { 
                let newItem = JSON.parse(item)
                let newRemovedValue = JSON.parse(removedValue)
                if (newItem.departmentId === newRemovedValue.departmentId && 
                    newItem.id === newRemovedValue.id) newSet.delete(item);
            });  
            break
          default: break
        }
    
        setSelectedPermissions(newSet)
        parentOnChange(newSet)
    }
    
    const selectService = (selected, _meta) => {
        if (!selected) return
        const selectedService = Number(selected.value)
        updateSelectedServices(new Set(selectedServices).add(selectedService))
    }

    const removeService = serviceId => {
        const newSet = new Set(selectedServices)
        newSet.delete(serviceId)
        updateSelectedServices(newSet)

        const newPermissions = new Set(selectedPermissions)
        services[serviceId].permissions.forEach(permission => {
            newPermissions.delete(JSON.stringify({ id : permission.id, departmentId : permission.department.id }))
        })
        setSelectedPermissions(newPermissions)
        parentOnChange(newPermissions)
    }
    
    const updateSelectedServices = selectedServices => {
        setSelectedServices(selectedServices)
        updateSelectServiceOptions(selectedServices)
    }

    const updateSelectServiceOptions = selectedServices => {
        const newSelectServiceOptions = Object.entries(services).filter(([serviceId, _service]) => (
        !selectedServices.has(Number(serviceId))
        )).map(([serviceId, service]) => ({
        value: Number(serviceId),
        label: service.name,
        }))
        
        setSelectServiceOptions(newSelectServiceOptions)
    }

    return <div>
        <CardHeader>
            <CardTitle tag="h4">User Permissions</CardTitle>
        </CardHeader>
        {
            [...selectedServices].map(serviceId => (
                <Row key={serviceId}>
                <Col className="pr-md-1" md="4">
                    <FormGroup>
                    <Label>Service</Label>
                    <Input value={services[serviceId].name} readOnly/>
                    </FormGroup>
                </Col>

                <Col className="pr-md-1" md="4">
                    <FormGroup>
                    <Label>Select Permissions</Label>
                    <Select
                        isMulti
                        name="permissions"
                        options={services[serviceId].permissions.map(permission => (
                            { label: permission.department.name.concat(" | ", permission.name) , 
                            value: JSON.stringify ({ id : permission.id, departmentId : permission.department.id }) }
                            ))}
                        onChange={selectPermission}
                        value={[...selectedPermissions].filter(newPermissions => 
                            {
                                const {id, departmentId} = JSON.parse(newPermissions)
                                return permissions.find(ele => id === ele.id && departmentId === ele.department.id).services[serviceId]
                            }
                            ).map(permissionsIds => {  
                            const {id, departmentId} = JSON.parse(permissionsIds)  
                            let newPermissions =   permissions.find(ele =>  id === ele.id && 
                                departmentId === ele.department.id)              
                            return { label: newPermissions.department.name.concat(" | ",  
                            newPermissions.name), 
                                value: JSON.stringify({id : newPermissions.id, 
                                    departmentId : newPermissions.department.id}) }
                                })}
                    />
                    </FormGroup>
                </Col>
                <Col className="pr-md-1 pt-3" md="1">
                    <Button color="danger" size='sm' className='mt-3' onClick={() => removeService(serviceId)} >-</Button>
                </Col>
                </Row>
            ))
        }

        {
            selectServiceOptions?.length > 0
            && <Row>
            
                <Col className="pr-md-1" md="4">
                    {/* single select */}
                    <FormGroup>
                    <label for="serviceSelect">Select Service</label>
                    <Select name="services" options={selectServiceOptions} onChange={selectService} value={null} id="serviceSelect" />
                    </FormGroup>
                </Col>

            </Row>
        }
    </div>
}

export default InputUserPermissions