/**import dependencies */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from "react-loader-spinner";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

import { isDataUrl } from 'libs/validators';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";

import Select from "react-select"

import "../assets/css/custom.css"
import InputUserPermissions from 'components/InputUserPermissions/InputUserPermissions';

function EditUserView(userDetails) {
  const routeName = userDetails.routes
  const featureName = userDetails.userDetails.featureNameList
  let result = featureName.includes(routeName);
  const [email, setEmail] = useState();
  const [designation, setDesignation] = useState()
  const [departmentId, setDepartmentId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [permissions, setPermissions] = useState([]);
  const [uploadFile, setUploadFile] = useState();
  const [defaultFile, setDefaultFile] = useState();
  const [fullName, setFullName] = useState();
  const history = useHistory();

  const [userPermissionOptions, setUserPermissionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([])

  const location = useLocation();
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');

    axios({
      method: 'get',
      url: `${apiUrl}departments`,
      headers: { 'Authorization': token },
    }).then(res => {
      const { departments } = res.data

      setDepartmentOptions(departments)
    })

  }, [])

  // validations for inputs
  const validations = {
    phoneNumber: /^(?:(0)|\+?(\d{1,4}))?[-. ]?(\d{3})[-. ]?(\d{3})[-. ]?(\d{4})$/,
    fullName: /^(?:[a-zA-Z]+ ?)+$/,
    designation: /^(?:[a-zA-Z]+ ?)+$/,
  }

  useEffect(() => {

    async function loadData() {
      setIsLoading(true)

      await getPermissions();
      await getUserData(location.state.user_id);

      setIsLoading(false)
    }

    setUserId(location.state.user_id);
    loadData();
    // getPermissions();
    // getUserData(location.state.user_id);

  }, [location]);

  /**get permission list in dropdown */
  async function getPermissions() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');
    let config = {
      method: 'get',
      url: `${apiUrl}permissions`,
      headers: { 'Authorization': token },
    };


    const response = await axios(config)

    let departmentsConfig = {
      method: 'get',
      url: `${apiUrl}departments`,
      headers: { 'Authorization': token },
    };

    const responseDepartments = await axios(departmentsConfig)
    // departmentOptions

    const permissions = response.data.permissions.map (permission => responseDepartments.data.departments.map(d => ({
      ...permission, 
      department : d,
  }
  ))).flat();



    setUserPermissionOptions(permissions)
  }

  /**get user details for edit */
  async function getUserData(userId) {

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');
    let config = {
      method: 'get',
      url: `${apiUrl}users/${userId}`,
      headers: { 'Authorization': token },
    };


    const getUserDetails = await axios(config)
    const user = getUserDetails.data.user

    setDepartmentId(user.department?.id)
    setDesignation(user.designation)
    setEmail(user.email)
    setFullName(user.fullName)
    setDefaultFile(user.profileImage)
    setPhoneNumber(user.phone)
    setPermissions(Object.values(user.permissions).map(permission => JSON.stringify({ id : permission.id, departmentId : permission.department.id })))

  }

  const isFormValid = () => {
    return phoneNumber?.match(validations.phoneNumber)
      && fullName?.match(validations.fullName)
      && designation?.match(validations.designation)
      && isDataUrl("image")(uploadFile)
  }

  /**get profile image */
  const profileImgChange = (e) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => { setUploadFile(reader.result) }
    if (file) reader.readAsDataURL(file)

  }

  /**submit the form */
  const onSubmit = async () => {

    if (!isFormValid()) {
      toast.warning("Please fill valid inputs!")
      return
    }

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');
    let config = {
      method: 'put',
      url: `${apiUrl}users/${userId}`,
      headers: { 'Authorization': token },
      data: {
        user: {
          "email": email,
          "profileImage": uploadFile,
          "phone": phoneNumber,
          "designation": designation,
          "departmentId": departmentId,
          "permissions": permissions.map(permission =>  { 
            let newPermission = JSON.parse(permission)
            return ({  id : newPermission.id, departmentId : newPermission.departmentId , })}),
          "fullName": fullName
        }
      }
    };



    /**Sweet alert */

    Swal.fire({
      title: 'Are you sure you want to update the User Details?',
      showCancelButton: true,
      confirmButtonText: 'Ok',

    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const submitValues = await axios(config);

          toast.success(submitValues.data.message);
          history.push({ pathname: '/admin/users' });

        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.message)
          }
          else {
            toast.error("Oops...Something went wrong...")
          }

        }
      }
      else {
        Swal.fire('Changes are not saved', '', 'info')

      }
    })

  }

  const onCancel = async () => {
    history.push({ pathname: '/admin/users' });
  }


  return (
    <>
      {
        result
          ? <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Edit User</CardTitle>
                  </CardHeader>
                  {
                    isLoading
                      ? <Loader type="ThreeDots" color="#3358f4" className="m-3" height={30} width={30} />
                      : <>
                        <CardBody>
                          <Form>
                            <Row>
                              <Col className="pr-md-1" md="3">
                                <FormGroup>
                                  <label>Full Name</label>
                                  <Input
                                    placeholder="Full Name"
                                    type="text"
                                    value={fullName}
                                    onChange={event => setFullName(event.target.value)}
                                    valid={fullName?.match(validations.fullName)}
                                    invalid={!fullName?.match(validations.fullName)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col className="px-md-1" md="3">
                                <FormGroup>
                                  <label htmlFor="exampleInputEmail1">
                                    Email
                                  </label>
                                  <Input placeholder="email" type="email" value={email} disabled />
                                </FormGroup>
                              </Col>
                              <Col className="px-md-1" md="3">
                                <FormGroup>
                                  <label>Designation</label>
                                  <Input
                                    placeholder="Designation"
                                    type="text"
                                    value={designation}
                                    onChange={event => setDesignation(event.target.value)}
                                    valid={designation?.match(validations.designation)}
                                    invalid={!designation?.match(validations.designation)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col className="pl-md-1" md="3">
                                <FormGroup>
                                  <Label>Department</Label>
                                  <Select
                                    name="department"
                                    options={departmentOptions.map(department => (
                                      { label: department.name, value: department.id }
                                    ))}
                                    onChange={d => setDepartmentId(d.value)}
                                    value={(() => {
                                      const department = departmentOptions.find(department => department.id === departmentId)
                                      return department
                                        ? { label: department.name, value: department.id }
                                        : null
                                    })()}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className='h-100'>
                              <Col md="4">
                                <FormGroup>
                                  <label>Phone Number</label>
                                  <Input
                                    value={phoneNumber}
                                    placeholder="Phone Number"
                                    type="text"
                                    onChange={event => setPhoneNumber(event.target.value)}
                                    valid={phoneNumber?.match(validations.phoneNumber)}
                                    invalid={!phoneNumber?.match(validations.phoneNumber)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col className="pr-md-1" md="4">
                                <FormGroup>
                                  <label for="exampleInputFile">Profile Picture</label>
                                  <Input
                                    id="profile-upload"
                                    name="file"
                                    type="file"
                                    onChange={profileImgChange}
                                    style={{
                                      color: "transparent"
                                    }}
                                    title=""
                                  />
                                </FormGroup>
                              </Col>
                              <Col className="pl-md-1" md="2">
                                <FormGroup>
                                  <img src={uploadFile || defaultFile || "https://bootdey.com/img/Content/avatar/avatar7.png"} alt="profile" />
                                </FormGroup>
                              </Col>

                            </Row>

                            <InputUserPermissions
                              options={userPermissionOptions}
                              values={permissions}
                              onChange={values => setPermissions(values)}
                            />

                          </Form>
                        </CardBody>
                        <CardFooter>

                          <Button className="btn-fill" color="info" type="submit" onClick={onSubmit}>
                            Save Changes
                          </Button>

                          <Button className="btn-fill" color="info" type="submit" onClick={onCancel}>
                            Cancel
                          </Button>
                        </CardFooter>
                      </>
                  }
                </Card>
              </Col>
            </Row>
          </div>
          : <div className="content">
            <Row className="unauthorized-msg-block">

              <CardBody className="unauthorized-msg-body">
                <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
              </CardBody>

            </Row>

          </div>
      }
    </>
  );
}

export default EditUserView;
