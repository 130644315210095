
/**dependencies */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import Swal from 'sweetalert2';

import { isDataUrl } from 'libs/validators';

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, FormGroup, CardTitle, Form, Input, Row, Col, } from "reactstrap";

/**import component */
import { useHistory } from "react-router-dom";


/**import style */
import "../assets/css/custom.css";

toast.configure()

function EditServiceView(userDetails) {
  const routeName = userDetails.routes
  const featureName = userDetails.userDetails.featureNameList
  let result = featureName.includes(routeName);
  const history = useHistory();
  const [serviceId, setServiceId] = useState();
  const [serviceName, setServiceName] = useState();
  const [url, setUrl] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [defaultImage, setDefaultImage] = useState();
  const location = useLocation();

  const validations = {
    serviceName: /^(?:[a-zA-Z]+ ?)+$/,
  }

  useEffect(() => {

    setServiceId(location.state.detail)

  }, [location.state.detail]);
  
  useEffect(() => {
    getServiceData();

  }, [serviceId])

  /**get the service data to be edited */

  async function getServiceData() {

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');

    let config = {
      method: 'get',
      url: `${apiUrl}services/${serviceId}`,
      headers: { 'Authorization': token },
    };

    const getValueEdit = await axios(config)

    setServiceName(getValueEdit.data.service.name);
    setUrl(getValueEdit.data.service.url);
    setDefaultImage(getValueEdit.data.service.image)

  }

  const isUrlValid = url => {
    try {
      return ["http:", "https:"].includes(new URL(url).protocol)
    } catch (error) {
      return false
    }
  }

  const isFormValid = () => {
    return serviceName.match(validations.serviceName)
    && isUrlValid(url)
    && isDataUrl("image")(imageUrl)
  }

  /**upload image */
  const uploadImg = (e) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => { setImageUrl(reader.result) }
    if (file) reader.readAsDataURL(file)

  }

  /**submit the form */
  const onSubmit = async () => {
    if (isFormValid()) {
    
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = sessionStorage.getItem('currentToken');
  
  
      let config = {
        method: 'put',
        url: `${apiUrl}services/${serviceId}`,
        headers: { 'Authorization': token },
        data: {
          service: {
            name: serviceName,
            image: imageUrl,
            url,
          }
        }
      };
  
        /**Sweet alert */
      
        Swal.fire({
        title: 'Are you sure you want to update the Service Details?',
        showCancelButton: true,
        confirmButtonText: 'Ok',
  
      }).then(async (result) => {
          if (result.isConfirmed) {
          try {
            const submitValues = await axios(config);
            toast.success(submitValues.data.message);
            if (submitValues.status === 200) {
              history.push({ pathname: '/admin/services' });
            
            }
      
          } catch (error) {
            if (error.response) {
              
              toast.error(error.response.data.message)
            }
            else {
              toast.error("Oops...Something went wrong...")
            }
          }
        }
        else {
          Swal.fire('Changes are not saved', '', 'info')
  
        }
      })
    }
    else {
      toast.warning("Please fill valid inputs!");
    }


  }

  const onCancel = async () => {
    history.push({ pathname: '/admin/services' });

  }

  return (
    <>
     {result ?
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Edit Service</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label htmlFor="feature name">
                          Service Name
                        </label>
                        <Input 
                          placeholder="Service Name" 
                          type="text"
                          value={serviceName}
                          onChange={event => setServiceName(event.target.value)}
                          valid={serviceName?.match(validations.serviceName)}
                          invalid={!serviceName?.match(validations.serviceName)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="5">
                      <FormGroup>
                        <label>Url</label>
                        <Input
                          value={url}
                          placeholder="Url"
                          type="text"
                          onChange={event => setUrl(event.target.value)}
                          valid={isUrlValid(url)}
                          invalid={!isUrlValid(url)}
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>

                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <img src={imageUrl || defaultImage} width="25%" height="25%" alt="Service Image" />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label for="exampleInputFile">Upload your image here</label>
                        <Input
                          id="profile-upload"
                          name="file"
                          type="file"
                          onChange={uploadImg}
                          style={{
                            color: "transparent"
                          }}
                          title=""
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="info" type="submit" onClick={onSubmit}>
                  Save Changes
                </Button>
                <Button className="btn-fill" color="info" type="submit" onClick={onCancel}>
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>:
      <div className="content">
          <Row className="unauthorized-msg-block">

            <CardBody className="unauthorized-msg-body">
              <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
            </CardBody>

          </Row>


        </div>
      }

    </>
  );
}

export default EditServiceView

