
/**dependencies */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isDataUrl } from 'libs/validators';

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, FormGroup, CardTitle, Form, Input, Row, Col } from "reactstrap";

/**import component */

import { useHistory } from "react-router-dom";

/**import style */
import "../assets/css/custom.css";

toast.configure()

function AddServiceView(props) {
  const routeName = props.routes
  const featureName = props.userDetails.featureNameList
  let result = featureName.includes(routeName);
  const history = useHistory();
  const [token, setToken] = useState();
  const [serviceName, setServiceName] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [url, setUrl] = useState();

  const validations = {
    serviceName: /^(?:[a-zA-Z]+ ?)+$/,
  }

  useEffect(() => {

    setToken(sessionStorage.getItem('currentToken'));

  }, []);

  /**upload image */
  const uploadImg = (e) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => { setUploadFile(reader.result) }
    if (file) reader.readAsDataURL(file)
  }

  const isUrlValid = url => {
    try {
      return ["http:", "https:"].includes(new URL(url).protocol)
    } catch (error) {
      return false
    }
  }

  const isFormValid = () => {
    return serviceName.match(validations.serviceName)
    && isUrlValid(url)
    && isDataUrl("image")(uploadFile)
    && uploadFile?.length
  }

  /**submit the form */
  const onSubmit = async () => {
    if (isFormValid()) {

      const apiUrl = process.env.REACT_APP_API_URL;

      let config = {
        method: 'post',
        url: `${apiUrl}services`,
        headers: { 'Authorization': token },
        data: {
          service: {
            name: serviceName,
            image: uploadFile,
            url
          }
        }
      };
      try {
        const submitValues = await axios(config);

        toast.success(submitValues.data.message);
         if(submitValues.status===200){
        history.push({ pathname: '/admin/services' });

         }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message)
        }
        else {
          toast.error("Oops...Something went wrong...")
        }
      }
    }
    else {
      toast.warning("Please fill valid inputs!");
    }

  }

  const onCancel = async () => {
    history.push({ pathname: '/admin/services' });

  }

  return (
    <>
     {result ?
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Add Service</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label htmlFor="feature name">
                          Service Name
                        </label>
                        <Input 
                          placeholder="Service Name" 
                          type="text" 
                          value={serviceName} 
                          onChange={event => setServiceName(event.target.value)}
                          valid={serviceName?.match(validations.serviceName)}
                          invalid={!serviceName?.match(validations.serviceName)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="3">
                      <FormGroup>
                        <label>Url</label>
                        <Input
                          value={url}
                          placeholder="Url"
                          type="text"
                          onChange={event => setUrl(event.target.value)}
                          valid={isUrlValid(url)}
                          invalid={!isUrlValid(url)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label for="exampleInputFile">Image</label>
                        <Input
                          id="profile-upload"
                          name="file"
                          type="file"
                          placeholder="Image"
                          onChange={uploadImg}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="info" type="submit" onClick={onSubmit}>
                  Submit
                </Button>
                <Button className="btn-fill" color="info" type="submit" onClick={onCancel}>
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>:
       <div className="content">
       <Row className="unauthorized-msg-block">

         <CardBody className="unauthorized-msg-body">
           <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
         </CardBody>

       </Row>


     </div>
    }

    </>
  );
}

export default AddServiceView

