
import React from "react";

// reactstrap components
import { Container} from "reactstrap";
import "../../assets/css/custom.css";
import {Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <div className="footer-content">
      <p className="col-md-4 mb-0 right-footer-content">© 2022 Gadgeon Smart Systems</p>
       <ul className="nav col-md-5 list-style">
        <li className="nav-item ms-2"><Link  className="nav-link px-2">Terms Of Use</Link></li>
        <li className="nav-item ms-2"><Link  className="nav-link px-2">Privacy Policy</Link></li>
        <li className="nav-item ms-2"><Link  className="nav-link px-2">Security</Link></li>
      </ul>
      </div>
      </Container>
    </footer>
  );
}

export default Footer;
