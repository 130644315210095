/**dependencies */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, FormGroup, CardTitle, Form, Input, Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom";

import InputPermissionFeatures from 'components/InputPermissionFeatures/InputPermissionFeatures';

/**import style */
import "../assets/css/custom.css";

toast.configure()

function AddPermissionView(props) {
  const routeName = props.routes
  const featureName = props.userDetails.featureNameList
  let result = featureName.includes(routeName);
  const history = useHistory();
  const [permissionName, setPermissionName] = useState();

  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const [features, setFeatures] = useState([]);

  const validations = {
    permissionName: /^(?:[a-zA-Z]+ ?)+$/,
  }

  useEffect(() => {
    getFeatures();
  }, [])
  
  /**get featrue options */
  async function getFeatures() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');
    let config = {
      method: 'get',
      url: `${apiUrl}features`,
      headers: { 'Authorization': token },
    };

    const response = await axios(config)

    setFeatures(response.data.features)
  }

  const isFormValid = () => {
    return permissionName.match(validations.permissionName)
    && selectedFeatures.length > 0
  }

  const onSubmit = async () => {
    if (isFormValid()) {

      const apiUrl = process.env.REACT_APP_API_URL;
      const token = sessionStorage.getItem('currentToken');
      let config = {
        method: 'post',
        url: `${apiUrl}permissions`,
        headers: { 'Authorization': token },
        data: {
          permission: {
            name: permissionName,
            features: selectedFeatures.map(feature => JSON.parse(feature))
          }
        }
      };

      try {
        const submitAddPermissions = await axios(config)

        toast.success(submitAddPermissions.data.message);
        if (submitAddPermissions.status === 200) {
        history.push({ pathname: '/admin/permissions' });
        }

      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message)
        }
        else {
          toast.error("Oops...Something went wrong...")
        }
      }
    } else {
      toast.warning("Please fill valid inputs!");
    }

  }

  const onCancel = async () => {
    history.push({ pathname: '/admin/permissions' });
  }

  /**Add new row for service and features */

  return (
    <>
     {result ?
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Add Permission</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="3">
                      <FormGroup>
                        <label>Permission Name</label>
                        <Input
                          placeholder="Permission Name"
                          type="text"
                          value={permissionName}
                          onChange={event => setPermissionName(event.target.value)}
                          valid={permissionName?.match(validations.permissionName)}
                          invalid={!permissionName?.match(validations.permissionName)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <InputPermissionFeatures
                    options={features}
                    values={selectedFeatures}
                    onChange={values => setSelectedFeatures(values)}
                  />

                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="info" type="submit" onClick={onSubmit}>
                  Submit
                </Button>
                <Button className="btn-fill" color="info" type="submit" onClick={onCancel}>
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>:
       <div className="content">
       <Row className="unauthorized-msg-block">

         <CardBody className="unauthorized-msg-body">
           <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
         </CardBody>

       </Row>


     </div>
    }

    </>
  );
}

export default AddPermissionView;
