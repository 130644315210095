import React, { useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import axios from 'axios';

import AdminLayout from "../../layouts/Admin/Admin";
import { Link, useLocation } from "react-router-dom";

function Authenticate ({...token}) {

   let location = useLocation()

    let parsed

    let parsedToken

    try {

      if( token.id_token ) {

         parsed = token.id_token

     } else {
 
       parsed = sessionStorage.getItem('currentToken')
       
 
     }
    
      parsedToken = jwt_decode(parsed);   

       
    } catch (error) {

      console.error(error);

      window.location.href = process.env.REACT_APP_LOGIN_URL
       
    }


    const [user, setUser] = useState({});
    const [userToken, setUserToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userDetails,setUserDetails] =useState({});
 
    useEffect(() => {
       getUser();
    }, [location]);
 
    /**for getting user */
    async function getUser() {
       try {          

        if (parsedToken.exp > Date.now() / 1000) {
                  
         const apiUrl = process.env.REACT_APP_API_URL;
         let config = {
            method: 'get',
            url: `${apiUrl}user/features`,
            headers: { 'Authorization': parsed },
         
         };
         const responseFeatureName = await axios(config)

            setUserDetails({
                email : parsedToken.email,
                name : parsedToken.name,
                picture : parsedToken.picture,
                featureNameList : responseFeatureName.data.features
                
             })
         
             setLoading(false);
             setUser({'isAuthenticated': true});
             setUserToken(parsedToken)

        } else {

         setUserToken(null)
         setUser({'isAuthenticated': false});

         window.location.href = process.env.REACT_APP_LOGIN_URL

        }

       } catch (err) {
          console.error(err);
          setLoading(false);
       }
    }

    return (
        <div data-testid="main-header">
  
           {userToken ? <AdminLayout {...userDetails}  user={user} data-testid="userpage" /> : <Link to={process.env.REACT_APP_LOGIN_URL} /> }
  
        </div>
     )
  }
 

export default Authenticate