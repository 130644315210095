
import React, { useEffect, useState } from 'react';
import "../assets/css/custom.css";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, Table, } from "reactstrap";

function UserList(userDetails) {

  const routeName = userDetails.routes
  const featureName = userDetails.userDetails.featureNameList

  let result = featureName.includes(routeName);
  const [statusUpdate, setStatusUpdate] = useState();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const [userPermissionList, setUserPermissionList] = useState({});
  const [expanded, setExpanded] = useState([]);


  const history = useHistory();
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: 'fullName',
      text: 'Name',


    },
    {
      dataField: 'email',
      text: 'Email',


    },
    {
      dataField: 'designation',
      text: 'Designation',

    },
    {
      dataField: 'department',
      text: 'BU',

    },
    {
      dataField: '',
      text: '',
      formatter: editUsers,
      align: 'center'

    },
    {
      dataField: 'status',
      text: '',
      formatter: buttonStatus,
      align: 'center'

    },
  ];

  /**for status button */
  function buttonStatus(cell, row) {
    let parsedToken;
    let parsed;
    parsed = sessionStorage.getItem('currentToken')
    parsedToken = jwt_decode(parsed);

    if (row.email === parsedToken.email) {
      return (<Button className="btn-fill" color="info" type="button" disabled>Enabled</Button>)
    }
    else if (cell === 1) {
      return (<Button className="btn-fill" color="info" type="button" onClick={() => statusChange(row)}>Enabled</Button>)
    }
    else if (cell === 0) {
      return (<Button className="btn-fill" color="danger" type="button" onClick={() => statusChange(row)}>Disabled</Button>)

    }

  }




  /**status enable/disable button function*/
  const statusChange = async (row) => {

    let id = row.id;
    let status = row.status;
    let changeStatus = status ? 0 : 1;

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = sessionStorage.getItem('currentToken');
    let config = {
      method: 'put',
      url: `${apiUrl}users/${id}`,
      headers: { 'Authorization': token },
      data: {
        user: { status: changeStatus },
      },
    };

    const responseStatus = await axios(config)

    toast.success(responseStatus.data.message);
    if (responseStatus.status === 200) {
      setStatusUpdate(changeStatus);

    }

  }

  /**edit permission */
  function editUsers(cell, row) {
    return (<span className="edit-permission-btn" onClick={() => editUsersPage(row)}><i className="fa fa-edit edit-icon"></i></span>)

  }

  const editUsersPage = async (row) => {
    let id = row.id;
    history.push({ pathname: '/admin/edituser', state: { user_id: id } });

  }


  const addUser = () => {
    history.push("/admin/adduser");
  }

  // use effect
  useEffect(() => {

    async function fetchData() {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = sessionStorage.getItem('currentToken');

      let config = {
        method: 'get',
        url: `${apiUrl}users`,
        headers: { 'Authorization': token },
      };


      const getValue = await axios(config)

      setUsers(getValue.data.users);

      if (getValue.data) {
        setLoading(false)
      }
      else {
        setLoading(true)
      }
    }
    fetchData();

  }, [statusUpdate]);

  useEffect(() => {
    setUserPermissionList({})
  }, [loading])

  /**expand row */
  const expandRow = {
    renderer: row => (
      userPermissionList[row.id]
        ? (
          userPermissionList[row.id].isLoading
            ? <Loader type="ThreeDots" color="#3358f4" height={30} width={30} />
            : <Table>
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Permissions</th>
                </tr>
              </thead>
              {
                <tbody>
                  {
                    Object.entries(userPermissionList[row.id].services).map(([i, service]) =>
                      <tr key={i}>
                        <td>{service.name}</td>
                        <td>
                          {
                            service.permissions.map((permission) => (
                              <span className="tag" key={permission.id}>{`${permission.department.name} | ${permission.name}`}</span>
                            ))
                          }
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              }
            </Table>
        ) : ""

    ),

    onlyOneExpanding: true, //for expanding only row at a time
    expandByColumnOnly: true,
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandColumnAlign: 'center',
    expanded: expanded,

    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return
    },

    onExpand: async (row, isExpand, rowIndex, e) => {
      let userId = row.id

      if (!isExpand) {
        setExpanded([])
        return
      } else {
        setExpanded([userId])
      }

      setUserPermissionList({
        ...userPermissionList,
        [userId]: { isLoading: true, services: {} }
      })
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = sessionStorage.getItem('currentToken');
      let config = {
        method: 'get',
        url: `${apiUrl}users/${userId}`,
        headers: { 'Authorization': token },
      };

      const response = await axios(config)

      const user = response.data.user

      const services = {}
      user.permissions.forEach(permission => {
        Object.entries(permission.services).forEach(([service_id, service_name]) => {
          let service = services[service_id]
          if (!service) service = { name: service_name, permissions: [] }
          services[service_id] = {
            ...service,
            permissions: [
              ...service.permissions,
              permission
            ]
          }
        })
      })

      setUserPermissionList({
        ...userPermissionList,
        [userId]: {
          isLoading: false,
          services,
        }
      })

    },

    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (!expandable) {
        return;
      }
      return (
        <Button type="button" className="btn-fill" color="info">View permissions</Button>
      );
    }

  };


  return (
    <>
      {
        result?
        <div className="content">
          <Row>
            <Col md="12">
              {loading ? <></> :
                <ToolkitProvider
                  keyField="id"
                  data={users}
                  columns={columns}
                  search
                >
                  {
                    props =>
                    (
                      <Card>

                        <CardHeader>
                          <Row>

                            <Col md="7">
                              <CardTitle tag="h4">Users</CardTitle>
                            </Col>
                            <Col md="3" className="d-flex align-items-center" >
                              <SearchBar className='w-100' {...props.searchProps} />
                            </Col>
                            <Col md="2" className="add-user-btn">
                              <Button className="btn-fill" color="info" type="button" onClick={addUser}>
                                Add New
                              </Button>
                            </Col>

                          </Row>
                        </CardHeader>

                        <CardBody>

                          {loading ? <Loader type="ThreeDots" color="#3358f4" height={30} width={30} /> :

                            <BootstrapTable bootstrap4 className="bootstrap-table"
                              striped hover condensed
                              pagination={paginationFactory()} expandRow={expandRow}
                              {...props.baseProps}
                            />
                          }

                        </CardBody>
                      </Card>
                    )
                  }
                </ToolkitProvider>}
            </Col>

          </Row>
        </div>
            :
          <div className="content">
          <Row className="unauthorized-msg-block">

            <CardBody className="unauthorized-msg-body">
              <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
            </CardBody>

          </Row>


        </div>
      }

    </>
  );
}

export default UserList;
