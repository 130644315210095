
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/**import style */
import "../assets/css/custom.css";

// reactstrap components
import { Button,Card,CardHeader,CardBody,CardTitle, Row,Col} from "reactstrap";
import Loader from "react-loader-spinner";



function FeatureList(userDetails) {
 
  const routeName = userDetails.routes
  const featureName = userDetails.userDetails.featureNameList
  let result = featureName.includes(routeName);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusUpdate, setStatusUpdate] = useState();

    // use effect
    useEffect(() => {

      async function fetchData() {
       const apiUrl = process.env.REACT_APP_API_URL;
        const token = sessionStorage.getItem('currentToken');
    
        let config = {
          method: 'get',
          url: `${apiUrl}features`,
          headers: { 'Authorization': token },
        };
  
        const getValue = await axios(config)

        setFeatures(getValue.data.features)
        if (getValue.data) {
          setLoading(false);
        }
        else {
          setLoading(true)
        }
      }
      fetchData()
    }, [statusUpdate]);
  

  

  const columns = [
    {
      dataField: 'name',
      text: 'Feature Name',

    }, {
      dataField: 'serviceName',
      text: 'Service Name',

    },
    {
      dataField: 'status',
      text: 'status',
      formatter: buttonStatus,
    },
  ];

    /**for status button */
    function buttonStatus(cell, row) {
      if (cell === 1) {
        return (<Button className="btn-fill" color="info" type="button" onClick={() => statusChange(row)}>Enabled</Button>)
      }
      else {
        return (<Button className="btn-fill" color="danger" type="button" onClick={() => statusChange(row)}>Disabled</Button>)
  
      }
  
    }
  
    /**status enable/disable button function*/
    const statusChange = async (row) => {
      let id = row.id;
      let status = row.status;
      
      let changeStatus = status ? 0 : 1;
      
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = sessionStorage.getItem('currentToken');
      let config = {
        method: 'put',
        url: `${apiUrl}features/${id}`,
        headers: { 'Authorization': token },
        data: { 'featureStatus': changeStatus },
      };
      
      const responseStatus = await axios(config)
      
      if (responseStatus.status === 200) {
        toast("Status updated successfully");
        setStatusUpdate(changeStatus);
  
      }
    }

  return (
    <>
    {result?
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="10">
                    <CardTitle tag="h4">Features</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              {loading ? <Loader type="ThreeDots" color="#3358f4" height={30} width={30} /> :
                  <BootstrapTable bootstrap4 keyField='id' className="bootstrap-table"
                    striped hover condensed data={features}
                    pagination={paginationFactory()} columns={columns} >
                  </BootstrapTable>
                }
              </CardBody>
            </Card>
          </Col>

        </Row>

      </div> :
    <div className="content">
    <Row className="unauthorized-msg-block">

      <CardBody className="unauthorized-msg-body">
        <h4 className="unauthorized-msg">Unauthorized Access. Please contact Support.</h4>
      </CardBody>

    </Row>


  </div>
  }
    </>
  );
}

export default FeatureList;
